/** @format */

import React from "react";
import Modal from "components/ui2/Modal";

import Button from "components/ui2/Button";
import Text from "components/ui/Text";
import { Row, Column } from "components/ui/Grid";
import { useMemo } from "react";

const DenyPaymentModal = ({ openModal, setOpenModal, bodyValue, paymentDetails, onConfirm }) => {
  const paidWith = useMemo(() => {
    let paidWith = "";

    if (paymentDetails?.primo_metodo && paymentDetails?.secondo_metodo) {
      paidWith = `pagato ${paymentDetails?.primo_metodo} e ${paymentDetails?.secondo_metodo}`;
    } else if (paymentDetails?.primo_metodo) {
      paidWith = `pagato ${paymentDetails?.primo_metodo}`;
    } else if (paymentDetails?.secondo_metodo) {
      paidWith = `pagato ${paymentDetails?.secondo_metodo}`;
    } else if (paymentDetails?.gratuito) {
      paidWith = `acquisito gratuitamente`;
    } else {
      paidWith = "c'è un errore di configurazione dell'acquisto";
    }

    return paidWith;
  }, [paymentDetails]);

  return (
    <Modal
      customModal
      open={openModal}
      header={() => <div style={{ width: "100%", height: "4rem" }} />}
      setOpenModal={setOpenModal}
      color="primary"
      width="55%"
      marginTop="2rem"
      mobileFullScreen="true"
    >
      <Text
        weight="bold"
        size="f6"
        value={`Attenzione, si sta procedendo all'annullo della richiesta e del relativo pagamento del servizio "${bodyValue?.nomeServizio?.it}" ${paidWith}, si vuole procedere con l'annullo?`}
      />
      <Row padding="0" fluid display="flex" justifycontent="center" margin="2rem 0rem 2rem 0rem">
        <Column xs="12" md="6" lg="4">
          <Button
            fontSize="f7"
            label="Annulla"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setOpenModal(false);
            }}
          />
        </Column>
        <Column xs="12" md="6" lg="4">
          <Button
            fontSize="f7"
            label="Conferma"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              onConfirm();
            }}
          />
        </Column>
      </Row>
    </Modal>
  );
};

DenyPaymentModal.displayName = "Deny Payment Modal";
export default DenyPaymentModal;
